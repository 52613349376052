import React from "react";
import CountUp from 'react-countup';

const Counters = (props) => {

    return (
        <>
            <h1 className="text-enter text-3xl text-maroon font-[500] mb-2 pb-2 border-b border-gray-200 capitalize">What We have done</h1>
            <div className="counters mt-3">
                <div className="row mb-3">
                    <div className="counter col-md-6">
                    <CountUp
                        start={0}
                        end={300}
                        duration={2.75}
                        delay={0}
                        separator=" "
                        // prefix="EUR "
                        suffix="+"
                        
                        >
                        {({ countUpRef }) => (
                                <div className="counter-digit text-red-500">
                                <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                        <div className="counter-text text-gray-500">Customers</div>
                    </div>
                    <div className="counter col-md-6">
                        <CountUp
                        start={0}
                        end={70}
                        duration={6.75}
                        delay={0}
                        separator=" "
                        // prefix="EUR "
                        suffix="+"
                        
                        >
                        {({ countUpRef }) => (
                                <div className="counter-digit text-blue-500">
                                <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>

                        <div  className="counter-text text-gray-500">Available projects</div>
                    </div>

                    <div className="counter col-md-6">
                        <CountUp
                        start={0}
                        end={10}
                        duration={8.75}
                        delay={0}
                        separator=","
                        // prefix="EUR "
                        suffix="+"
                        
                        >
                        {({ countUpRef }) => (
                                <div className="counter-digit text-yellow-500">
                                    <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                        <div className="counter-text text-gray-500">Areas</div>
                    </div>

                    <div className="counter col-md-6">
                        <CountUp
                        start={0}
                        end={12}
                        duration={8.75}
                        delay={0}
                        separator=","
                        // prefix="EUR "
                        suffix="+"
                        
                        >
                        {({ countUpRef }) => (
                                <div className="counter-digit text-green-500">
                                    <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                        <div className="counter-text text-gray-500">countries</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Counters)