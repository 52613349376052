import React from "react";
import PageHeader from "../utils/page-header";
import { FaStar } from "react-icons/fa";


const About = (props) => {

    return (
        <>
            <PageHeader title="About" />
            <section>
                <div className="container">
                    <h2 className="text-2xl text-gray-600 my-2">KEY FOCUS AREAS</h2>

                    <div className="mb-3">
                        With management and support teams in our Nairobi office overseeing field operations in all locations across Kenya, Dataworld Research Ltd is placed well to respond swiftly to today’s pressing research needs in Kenya and beyond. Our total sphere of operations in East Africa includes Kenya, Somalia, Rwanda, Ethiopia, Tanzania, DRC, Burundi and South Sudan. Dataworld Research maintains staff of nearly 50 Research Assistants across country’s myriad dialects, diverse cultures, and vast sub-regions, allowing us access to the entire country. We invest heavily in our staff members, as we understand that they maintain the key to our success. They are regularly trained, updated, and incentivized to perform at the highest levels.
                    </div>  
                </div>
            </section>
            <section className="py-5 bg-gray-100 my-5">
                <div className="container">
                <h2 className="text-2xl text-gray-600 my-2">Mission</h2>
                    <div className="text-gray-400 text-xl mb-4">
                        “To provide industry-leading research services to both national and international NGOs, multilaterals, private interests, and government agencies, to enable them to make the best decisions possible”
                    </div>

                    <h2 className="text-2xl text-gray-600 my-2">Our Values</h2>
                    <div className="row mb-5">
                        <ul className="col-md-6">
                            <li className=""><FaStar className="inline-block mr-2"/>Integrity and Stewardship </li>
                            <li className=""><FaStar className="inline-block mr-2"/>Responsibility </li>
                            <li className=""><FaStar className="inline-block mr-2"/>Loyalty and Commitment</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Integrity and Stewardship</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Trust </li>
                        </ul>
                        <ul className="col-md-6">
                            <li className=""><FaStar className="inline-block mr-2"/>Lawfulness</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Openness and Respect</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Learning</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Continuous Learning</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Leadership</li>
                        </ul>
                    </div>

                    <h2 className="text-2xl text-gray-600 my-2">Our Values</h2>
                    <p>Dataworld Research Ltd is committed to and fully subscribes to the professional principles of practice as outlined in the Africa Evaluation Guidelines (2004), namely:</p>
                    <ol className="std-list" style={{listStyle:"decimal"}}>
                        <li>Meeting the clients’ requirements Ensuring that the results meet the information needs of intended users and be owned by the stakeholders.</li>
                        <li>Integrity and objectivity Our report findings ensure that information is technically adequate and accurate with respect to the situation on the group</li>
                        <li>Responsibility at all stages of the consultancy We ensure that legal and ethical issues are part of the consultancy and assume responsibility with our clients for our work.</li>
                        <li>Participatory approaches and transfer of skills Ensure that results are feasible, realistic and diplomatic.</li>
                    </ol>
                </div>
            </section>
            <section className="intro mt-3">
                <div className="container">
                <h2 className="text-2xl">Our Story</h2>
                <p>Dataworld
                Research, one of the fastest growing Kenyan consulting firm, offers the best
                local expertise available in Kenya– with the right level of relationships,
                insight and scalable support staff. Dataworld Research is a Kenyan-based
                professional service firm with a global experience. It is a specialized team of
                professionals with competency including Project Design, Socio- economic
                research, Private Sector Development, Strategic Planning, Environmental
                Assessment, Monitoring and Evaluation, and Training. Dataworld Research was
                founded by a group of professionals from diverse backgrounds and with broad
                expertise, having an extensive and solid research and training consulting
                experience in the region. With its headquarters in Nairobi, Kenya, Dataworld
                offers local skills, knowledge, and experience necessary to support national
                initiatives of large scale and scope.
            </p>

            <p>Dataworld
                Research was established in 2007 to focus on small research activities in
                Nairobi, but has now grown to supporting organizations all over Africa. Our
                head office is in Nairobi. The firm is legally registered in Kenya
                (CPR/2012/90780) and has worked on projects in more than 5 countries around Africa.
            </p>

            <p>
                In our world of rapid change, the need for reliable information to make confident decisions has never been greater. At Dataworld Research Ltd we believe our clients need more than a data supplier, they need a partner who can produce accurate and relevant information and turn it into actionable truth. This is why our passionately curious experts not only provide the most precise measurement, but shape it to provide True Understanding of Society, Markets and People. To do this we use the best of science, technology and know-how and apply the principles of security, simplicity, speed and substance to everything we do. So that our clients can act faster, smarter and bolder. Ultimately, success comes down to a simple truth:
            </p>
            
            </div>
            </section>
        </>
    )
}

export default React.memo(About)