import React from "react";
import { VerticalTicker, HorizontalTicker } from "react-infinite-ticker";
import { Link } from "react-router-dom";

const Partnerships = () => {
    // let imgPath = require(`../../assets/img/partnerships/omart.jpg`);
    let img = require(`../../assets/img/bpl.png`)
    const images = [
        require(`../../assets/img/partnerships/acal.png`),
        require(`../../assets/img/partnerships/gibb.png`),
        require(`../../assets/img/partnerships/hand.png`),
        require(`../../assets/img/partnerships/ibtc.png`),
        require(`../../assets/img/partnerships/IMG-20220928-WA0011.jpg`),
        require(`../../assets/img/partnerships/iussp.png`),
        require(`../../assets/img/partnerships/kalro.png`),
        require(`../../assets/img/partnerships/keet.png`),
        require(`../../assets/img/partnerships/hand.png`),
        require(`../../assets/img/partnerships/kenya-redcross.png`),
        require(`../../assets/img/partnerships/kgga.png`),
        require(`../../assets/img/partnerships/lisp.png`),
        require(`../../assets/img/partnerships/ormat.jpg`),
        require(`../../assets/img/partnerships/st-paul.png`),
        require(`../../assets/img/partnerships/unhcr.png`),

      ];
    return (
            <div className="container">
                <div className="mb-5">
                <Link to={"/our-projects"} className="border-b border-gray-100 pb-2 block w-auto">
                    <h1 className="block-title text-4xl font-[500] text-gray-500 capitalize">Project Partners</h1>
                    {/* <h2 className="block-subtitle text-2xl font-[500] capitalize mb-2">Our Partners</h2> */}
                </Link>
                </div>
                <div className="tracker-img-sizer mt-3" style={{maxWidth: "500px", marginLeft:"auto", marginRight:"auto"}}><HorizontalTicker duration={65000}>
                    {images.map((img, idx) => (
                        <>
                            <img src={img} alt={img}/>
                        </>
                    ))}
                    <img src={img} alt=""/>
                </HorizontalTicker>
                </div>
            </div>
    )
}

export default React.memo(Partnerships);