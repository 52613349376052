import React from "react";
import PageHeader from "../utils/page-header";
// "/node_modules/react-grid-layout/css/styles.css"
// "/node_modules/react-resizable/css/styles.css"
import GridLayout from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Hand from "../../assets/img/projectslogos/hand.png";
import Film from "../../assets/img/projectslogos/film-ad.png";
import RedCross from"../../assets/img/projectslogos/kenya-redcross.png";
import Cardno from "../../assets/img/projectslogos/cardno.png";
import UNFPA from "../../assets/img/projectslogos/unhcr.png";
import Highlands from "../../assets/img/projectslogos/central-highlands.png";
import KGGA from "../../assets/img/projectslogos/kgga.png";
import Central from "../../assets/img/projectslogos/hand.png";
import Special from "../../assets/img/projectslogos/special-programs.png";
import Ormat from "../../assets/img/projectslogos/ormat.png";
import Expert from "../../assets/img/projectslogos/expert-icon2.png";
import Kalro from "../../assets/img/projectslogos/kalro.png"
import { Carousel } from "react-bootstrap";
const Projects = () => {
    const layout = [
        { i: 'a', x: 0, y: 0, w: 1, h: 8 },
        { i: 'b', x: 1, y: 1, w: 1, h: 8 },
        { i: 'c', x: 2, y: 2, w: 1, h: 10 },
        { i: 'd', x: 0, y: 0, w: 1, h: 8 },
        { i: 'e', x: 1, y: 1, w: 1, h: 9 },
        { i: 'f', x: 2, y: 2, w: 1, h: 8 },
        { i: 'g', x: 0, y: 0, w: 1, h: 8 },
        { i: 'h', x: 1, y: 1, w: 1, h: 8 },
        { i: 'i', x: 2, y: 2, w: 1, h: 8 },
        { i: 'j', x: 0, y: 0, w: 1, h: 8 },
        { i: 'k', x: 1, y: 1, w: 1, h: 8 },
        { i: 'l', x: 2, y: 2, w: 1, h: 8 },
      ];

      const data = [
        {
            img: Hand,
            key: "a",
            bg: "blue",
            title: "Hand in Hand Eastern Africa",
            detail: "Hilti Cohort of Entrepreneurs Project in Kiambu, Machakos and Muranga Counties Task: End line Evaluation"
        },
        {
            img: Film,
            key: "b",
            bg: "",
            title: "FilmAid Kenya",
            detail: "Endline Evaluation Tafsiri Project in Kakuma Refugee camp: The project seeks to enhance access to educational content for primary school-age children"
        },
        {
            img: RedCross,
            key: "c",
            bg: "green",
            title: "Kenya Redcross Society",
            detail: "End Term Evaluation for Covid-19 Response and Recovery Project in 14 target counties of Nairobi, Mombasa, Kilifi, Mandera, Kiambu, Kajiado, Kitui, Nakuru, Siaya, Homabay, Kakamega, Kwale, Uasin Gishu and Busia. The project targeted COVID -19 case management and control, protection services; GBV prevention and control, and provision of Psychosocial support with a direct target of 929,585 individuals in these 14 counties"
        },
        {
            img: Cardno,
            key: "d",
            bg: "purple",
            title: "Cardno Agosystems and Emerging Markets Ltd",
            detail: "Data cleaning and Analysis best practices on the Baseline survey data commissioned by AGRA in Ghana, Nigeria and Niger regions at their regional office in Nairobi. Assisted in report preparation"
        },
        {
            img: UNFPA,
            key: "e",
            bg: "green",
            title: "UNFPA -Somalia",
            detail: "Socio-economic impact of COVID-19 assessment. A household based quantitative assessment measuring the impact of COVID-19 on health, education, gender, household food security and resilience in Somalia"
        },
        {
            img: Highlands,
            key: "f",
            bg: "blue",
            title: "Central Highlands of Kenya",
            detail: " Mid Term Evaluation of Food Security Enhancement Research Project in Smallholder Farms of Central Highlands of Kenya"
        },
        {
            img: KGGA,
            key: "g",
            bg: "purple",
            title: "Kenya Girl Guide Association",
            detail: " Situational analysis of Alcohol prevalence in Kenya and providing data segregated by gender at County levels for the 47 counties"
        },
        {
            img: Central,
            key: "h",
            bg: "blue",
            title: "Central Highlands of Kenya",
            detail: "Hilti Cohort of Entrepreneurs Project in Kiambu, Machakos and Muranga Counties Task: End line Evaluation"
        },
        {
            img: Special,
            key: "i",
            bg: "yellow",
            title: "Ministry of Special Programmes (Kenya)",
            detail: "Baseline study of Western Kenya Community Driven Development and Flood Mitigation (WKCDD & FM)"
        },
        {
            img: Ormat,
            key: "j",
            bg: "orange",
            title: "Orpower ltd",
            detail: "Community Needs Assessment (CNA), synthesized environmental and social policies to stakeholders"
        },
        {
            img: Expert,
            key: "k",
            bg: "green",
            title: "East Africa Grain Council (EAGC)",
            detail: "Final Evaluation of a Program on the Promotion of Structured Grain Trading System in Eastern and Southern Africa, Funded by Swedish International Development Agency (Sida) and Implemented by -"
        },
        {
            img: Kalro,
            key: "l",
            title: "KALRO",
            bg: "blue",
            detail: "Evaluation of Kenya Agricultural Productivity Project (KAPP)."
        },
        
      ]

      const getImageSrc = (img_link) => {

        let image;
        try {
            image = require(img_link);
        } catch (error) {
            image = require(`../../assets/img/projectslogos/hand.png`);
        }
        finally {
            image = require(img_link);
        }
        return image
    }
    return (
        <>
            <PageHeader title={"Our Projects"} />

            <section className="bg-ue-500 text-gray-500 text-xl mt-4 ">
                <div className="container py-4 text-center">Dataworld embraces synergy and consolidate the diversity of our expertise to provide peak development solutions for both state and non-state actors. Our solutions have the following main footprints;</div>
            </section>

            <section className="projects-grid py-7 bg-gray-200 text-center">
                <div className="container">
                    {/* show grid for desktop only */}
                    <div className="hidden md:block">
                        <GridLayout className="layout text-gray-200" layout={layout} cols={3} rowHeight={30} width={1200}>
                            {data.map((item, idx) => (
                                <div className={`bg-primary-black relative ${item?.bg}`} key={item.key} style={{ border: '5px solid white', padding:"10px" }}>
                                    <div className="text-center projects-grid-logos"><img className="" src={item.img} alt=""/></div>
                                    <h1 className="my-2 text-center text-white uppercase">{item.title}</h1>
                                    <div className=" mb-4">
                                        {item.detail}
                                    </div>
                                    <button></button>
                                </div>
                            ))}
                            
                        </GridLayout>
                    </div>

                    <div className="md:hidden">
                    <Carousel data-bs-theme=""
                        autoPlay={true}
                        interval={3000}
                        // controls={false}
                        indicators={false}
                        className='text-white pt-2'
                        >
                            {data.map((item, idx) => (
                                <Carousel.Item>
                                    <div className="bg-primary-black " key={item.key} style={{ border: '5px solid white', padding:"10px" }}>
                                        <div className="text-center projects-grid-logos"><img className="" src={item.img} alt=""/></div>
                                        <div className="my-2 text-center text-white uppercase">{item.title}</div>
                                        <div className=" mb-4">
                                            {item.detail}
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(Projects)